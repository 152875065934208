<template>
  <section class="rifas">
    <!-- Item do back -->
    <div class="item" v-for="(rifa, index) in storeRifas.rifas" :key="index">
      <!-- Foto -->
      <div class="foto" :style="`background-image: url('${rifa.fotos[0]}')`"></div>
      <!-- Info -->
      <div class="info">
        <div class="status" :class="{ ativo: rifa.ativo }">
          <span></span>
          <a :href="`https://app.oficinadasrifas.com/${rifa.hotlink}`" target="_blank" v-if="rifa.ativo">Ativa em app.oficinadasrifas.com/{{ rifa.hotlink }}</a>
          <p v-else>Rifa Inativa / Encerrada</p>
        </div>
        <h2>{{ rifa.titulo }}</h2>
        <div class="faixa" :class="{ ativo: rifa.ativo }">
          <div class="atual" :style="`width: ${rifa.porcentagem}%`"></div>
        </div>
        <div class="quantidade">
          <h3>X de {{ formatarQuantidadeBilhetes(rifa.quantidadeBilhetes) }} números vendidos</h3>
          <h4>X%</h4>
        </div>
      </div>
      <!-- Dados -->
      <div class="dados">
        <div class="dado">
          <Svgs nome="usuarios" />
          <h3>X</h3>
          <p>Compradores</p>
        </div>
        <div class="dado meio">
          <Svgs nome="cupom" />
          <h3>{{ formatarQuantidadeBilhetes(rifa.quantidadeBilhetes) }}</h3>
          <p>Bilhetes</p>
        </div>
        <div class="dado">
          <Svgs nome="calendario" />
          <h3>{{ formatarData(rifa.dataFinal) }}</h3>
          <p>Dia do Sorteio</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreRifas } from '@stores'
import Svgs from '@svgs'

const storeRifas = useStoreRifas()

function formatarData(dataISO) {
  const data = new Date(dataISO)
  const dia = String(data.getUTCDate()).padStart(2, '0')
  const mes = String(data.getUTCMonth() + 1).padStart(2, '0')
  const ano = data.getUTCFullYear()

  return `${dia}/${mes}/${ano}`
}

function formatarQuantidadeBilhetes(valor) {
  return valor.toLocaleString('pt-BR')
}
</script>

<style scoped>
section.rifas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 30px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-escuro-2);
  border: 2px solid var(--cor-escuro-2);
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.item:hover {
  border: 2px solid var(--cor-escuro-5);
}

.item .foto {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.item .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
  width: 100%;
  max-width: 400px;
}

.item .info .status {
  display: flex;
  align-items: center;
}

.item .info .status span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.item .info .status.ativo span {
  background-color: var(--cor-verde);
}

.item .info .status span {
  background-color: var(--cor-vermelho);
}

.item .info .status a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-verde);
  margin: 0 0 0 5px;
  transition: all 0.3s;
}

.item .info .status a:hover {
  color: var(--cor-verde-escuro);
}

.item .info .status p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 0 5px;
}

.item .info h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0 15px 0;
}

.item .info .faixa {
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background-color: var(--cor-escuro-3);
  position: relative;
}

.item .info .faixa .atual {
  height: 4px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.item .info .faixa.ativo .atual {
  background-color: var(--cor-verde);
}

.item .info .faixa .atual {
  background-color: var(--cor-vermelho);
}

.item .info .quantidade {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
}

.item .info .quantidade h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

.item .info .quantidade h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-escuro-5);
  margin: 0 0 0 5px;
}

.item .dados {
  display: flex;
  align-items: flex-end;
}

.item .dados .dado {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.item .dados .dado.meio {
  border-left: 1px solid var(--cor-escuro-4);
  border-right: 1px solid var(--cor-escuro-4);
}

.item .dados .dado svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
}

.item .dados .dado h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0 4px 0;
}

.item .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

@media screen and (max-width: 1000px) {
  section.rifas {
    padding: 0 20px;
  }

  .item {
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--cor-escuro-2);
    padding: 10px;
    border-radius: 10px;
    margin: 20px 0 0 0;
  }

  .item .foto {
    width: 100px;
    height: 100px;
  }

  .item .info {
    padding: 0 10px 0 15px;
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }

  .item .info h2 {
    margin: 10px 0 12px 0;
  }

  .item .info .faixa {
    height: 3px;
  }

  .item .info .faixa .atual {
    height: 3px;
  }

  .item .info .quantidade {
    margin: 12px 0 0 0;
  }

  .item .dados {
    width: 100%;
    padding: 25px 0 0 0;
  }

  .item .dados .dado {
    width: 33.33%;
    padding: 0 0 15px 0;
  }

  .item .dados .dado svg {
    width: 13px;
    min-width: 13px;
    max-height: 13px;
  }

  .item .dados .dado p {
    font-size: var(--f0);
  }

  .item .info .status a {
    font-size: var(--f0);
  }

  .item .info .status p {
    font-size: var(--f0);
  }
}
</style>
