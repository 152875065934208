<template>
  <section class="limites">
    <div class="assinatura">
      <div class="icone">
        <img :src="`https://arquivos.levelmember.com/imagens/planos/plano-essencial.png`" />
      </div>
      <div class="nome">
        <h3>Plataforma Premium</h3>
        <p>Assinatura Vitalícia</p>
      </div>
      <div class="areas">
        <p>
          3 rifas /
          <span>∞</span>
        </p>
        <div class="progresso">
          <div class="atual" style="width: 80%"></div>
        </div>
      </div>
      <div class="alunos">
        <p>
          10.301 bilhetes /
          <span>∞</span>
        </p>
        <div class="progresso">
          <div class="atual" style="width: 80%"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style scoped>
section.limites {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 20px 30px;
}

.assinatura {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-escuro-2);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.assinatura .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: var(--cor-escuro-1);
}

.assinatura .icone img {
  width: 100%;
  max-width: 35px;
}

.assinatura .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
}

.assinatura .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.assinatura .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
  text-transform: capitalize;
}

.assinatura .areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px 0 0;
  border-right: 1px solid var(--cor-escuro-2);
}

.assinatura .areas p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 0 0 10px 0;
}

.assinatura .areas .progresso {
  width: 100%;
  min-width: 200px;
  height: 5px;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  transition: all 0.5s;
}

.assinatura .areas .progresso .atual {
  height: 5px;
  width: 0%;
  background: linear-gradient(90deg, var(--degrade));
  border-radius: 10px;
  transition: all 0.5s;
}

.assinatura .alunos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px 0 30px;
}

.assinatura .alunos p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 0 0 10px 0;
}

.assinatura .alunos .progresso {
  width: 100%;
  min-width: 200px;
  height: 5px;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  transition: all 0.5s;
}

.assinatura .alunos .progresso .atual {
  height: 5px;
  width: 0%;
  background: linear-gradient(90deg, var(--degrade));
  border-radius: 10px;
  transition: all 0.5s;
}

@media screen and (max-width: 1000px) {
  section.limites {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .assinatura {
    flex-wrap: wrap;
    padding: 20px;
  }

  .assinatura .icone {
    width: 50px;
    height: 50px;
  }

  .assinatura .icone img {
    max-width: 25px;
  }

  .assinatura .nome {
    padding: 0 0 0 20px;
  }

  .assinatura .areas {
    height: auto;
    width: 50%;
    padding: 20px 20px 0 0;
    margin: 20px 0 0 0;
  }

  .assinatura .areas p {
    display: flex;
    flex-direction: column;
    font-size: var(--f1);
    line-height: 1.5;
    text-align: center;
  }

  .assinatura .areas .progresso {
    width: 100%;
    min-width: auto;
  }

  .assinatura .alunos {
    height: auto;
    width: 50%;
    padding: 20px 0 0 20px;
    margin: 20px 0 0 0;
  }

  .assinatura .alunos p {
    display: flex;
    flex-direction: column;
    font-size: var(--f1);
    line-height: 1.5;
    text-align: center;
  }

  .assinatura .alunos .progresso {
    width: 100%;
    min-width: auto;
  }
}
</style>
