<template>
  <Modal nome="criarRifa">
    <label>
      Título
      <span>*</span>
    </label>
    <input v-model="state.titulo" type="text" spellCheck="false" placeholder="Ex: Rifa BMW 320i" />

    <label>
      Descrição
      <span>*</span>
    </label>
    <textarea v-model="state.descricao" type="text" spellCheck="false" placeholder="Ex: Rifa autorizada pelo número 000" v-redimensionar-textarea></textarea>

    <label>
      URL padrão da rifa
      <span>*</span>
    </label>
    <div class="inputUrl">
      <p>app.oficinadasrifas.com/</p>
      <input v-model="state.hotlink" type="text" spellCheck="false" autocomplete="false" @input="validarHotlink()" placeholder="sua-rifa" />
    </div>

    <label>
      Data do sorteio
      <span>*</span>
    </label>
    <input v-model="state.dataFinal.dia" type="tel" spellCheck="false" placeholder="00/00/0000" v-mask="'##/##/####'" />

    <label>
      Horário do sorteio
      <span>*</span>
    </label>
    <input v-model="state.dataFinal.hora" type="tel" spellCheck="false" placeholder="00:00" v-mask="'##:##'" />

    <label>
      Quantidade de bilhetes
      <span>*</span>
    </label>
    <div class="inputInstrucao">
      <p>Selecione abaixo quantos bilhetes terão disponíveis em sua rifa. Esse valor não poderá ser alterado futuramente, apenas criando uma nova rifa.</p>
    </div>
    <div class="select">
      <Svgs class="icone" nome="setinha" />
      <select v-model.number="state.quantidadeBilhetes">
        <option :value="1000">1.000</option>
        <option :value="10000">10.000</option>
        <option :value="100000">100.000</option>
        <option :value="1000000">1.000.000</option>
        <option :value="10000000">10.000.000</option>
      </select>
    </div>

    <div class="inputs">
      <div class="input" v-for="(pacote, index) in state.pacotes" :key="`pacote-${index}`">
        <label>Pacote {{ index + 1 }}</label>
        <input type="tel" placeholder="0" spellcheck="false" v-model="state.pacotes[index]" />
        <Svgs class="icone" nome="cupom" />
      </div>
    </div>

    <label>Regulamento e Regras (opcional)</label>
    <textarea v-model="state.regulamento" spellCheck="false" placeholder="Digite aqui..." v-redimensionar-textarea></textarea>

    <label class="link" :class="{ subindo: state.uploadImagem.desabilitarBotao, maior: state.fotos.length === 0 }">
      Fotos do prêmio
      <button @click="adicionarImagem" :disabled="state.uploadImagem.desabilitarBotao">
        <span></span>
        <Svgs nome="mais" />
        Adicionar imagem
        <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
      </button>
    </label>
    <div class="imagens">
      <div v-for="(foto, index) in state.fotos" :key="index" class="imagem" :style="`background-image: url('${foto}')`">
        <button class="excluir" @click="removerImagem(index)">
          <Svgs nome="excluir" />
        </button>
      </div>
    </div>

    <SalvarModal nome="Criar Nova Rifa" @click="criarRifa()" />
  </Modal>
</template>

<script setup>
import { ref, reactive, inject, onMounted } from 'vue'
import { useStoreRifas, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadImagem from '@components/global/upload/image.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeRifas = useStoreRifas()
const storeModal = useStoreModal()

const uploadImagem = ref(null)

const state = reactive({
  titulo: '',
  descricao: '',
  hotlink: '',
  dataFinal: {
    dia: '',
    hora: ''
  },
  quantidadeBilhetes: 1000,
  regulamento: '',
  fotos: [],
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  },
  pacotes: Array(4).fill('')
})

async function criarRifa() {
  if (!validarDados()) return

  const dataFinalFormatada = formatarDataFinal()

  const payload = {
    titulo: state.titulo,
    descricao: state.descricao,
    hotlink: state.hotlink,
    dataFinal: dataFinalFormatada,
    quantidadeBilhetes: Number(state.quantidadeBilhetes),
    regulamento: state.regulamento,
    pacotes: state.pacotes.filter((p) => p !== ''),
    ativo: true,
    fotos: state.fotos.map((foto) => foto)
  }

  const adicionadoOk = await storeRifas.adicionar(payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarRifas')
  storeModal.fecharModal('criarRifa')
  limparCampos()
}

function formatarDataFinal() {
  const { dia, hora } = state.dataFinal

  if (!dia || !hora) return null

  const [diaPart, mesPart, anoPart] = dia.split('/')
  const [horaPart, minutoPart] = hora.split(':')

  const data = new Date(`${anoPart}-${mesPart}-${diaPart}T${horaPart}:${minutoPart}:00.000Z`)

  return data.toISOString()
}

function validarHotlink() {
  let hotlink = state.hotlink

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlink = hotlink
}

function limparCampos() {
  state.titulo = ''
  state.descricao = ''
  state.hotlink = ''
  state.dataFinal = {
    dia: '',
    hora: ''
  }
  state.quantidadeBilhetes = 1000
  state.regulamento = ''
  state.fotos = []
  state.pacotes = Array(4).fill('')
}

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.fotos.push(dados.urlImagem)
  }
}

const adicionarImagem = async () => {
  const urlImagem = await uploadImagem?.value?.enviarImagem()
  if (urlImagem) {
    state.fotos.push(dados.urlImagem)
  }
}

const removerImagem = (index) => {
  state.fotos.splice(index, 1)
}

function validarDados() {
  let mensagem
  if (!state.titulo || !state.hotlink || !state.descricao || !state.dataFinal || !state.quantidadeBilhetes) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
  position: relative;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

label.link span {
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid var(--cor-branco);
  border-top: 3px solid transparent;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  animation: girando 0.3s linear infinite;
}

label.link.subindo button {
  opacity: 0.5;
  pointer-events: none;
}

label.link.subindo span {
  opacity: 1;
  visibility: visible;
}

.inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 50px 0 0 0;
}

.inputs .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(25% - 10px);
}

.inputs .input label {
  position: absolute;
  top: -45px;
  left: 0;
}

.imagens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.imagens .imagem {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 120px;
  width: calc(50% - 5px);
  background-color: var(--cor-escuro-2);
  margin: 10px 0 0 0;
  border-radius: 10px;
  position: relative;
}

.imagens .imagem button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: var(--cor-vermelho);
  border-radius: 10px;
  transition: all 0.3s;
}

.imagens .imagem button:hover {
  background-color: var(--cor-vermelho-escuro);
}

.imagens .imagem button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
}
</style>
