<template>
  <section class="topo">
    <div class="capa">
      <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
    </div>
    <div class="opcoes">
      <div class="nome">
        <h3>{{ storePerfil.usuario?.nome }}</h3>
        <p>{{ storePerfil.usuario?.email }}</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStorePerfil } from '@stores'

const storePerfil = useStorePerfil()
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.capa {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  background: linear-gradient(50deg, #ffea00, #ff8800);
  background: -webkit-linear-gradient(50deg, #ffea00, #ff8800);
  height: 200px;
  z-index: 3;
}

.capa .foto {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 20px;
  bottom: -85px;
}

.opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 210px;
  width: 100%;
  z-index: 2;
}

.opcoes .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.opcoes .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.opcoes .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 8px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .capa {
    height: 100px;
  }

  .capa svg {
    width: 200px;
    max-width: 200px;
    position: absolute;
    filter: blur(2px);
  }

  .capa .foto {
    width: 150px;
    height: 150px;
    left: auto;
    bottom: -80px;
  }

  .opcoes {
    padding: 100px 20px 30px 20px;
  }

  .opcoes .nome {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}
</style>
