<template>
  <div id="rifas">
    <SectionTopo />
    <SectionFiltros />
    <SectionRifas />
    <ModalCriarRifa />
  </div>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import { useStoreRifas } from '@stores'
import SectionTopo from '@components/admin/rifas/partials/SectionTopo.vue'
import SectionFiltros from '@components/admin/rifas/partials/SectionFiltros.vue'
import SectionRifas from '@components/admin/rifas/partials/SectionRifas.vue'
import ModalCriarRifa from '@components/admin/rifas/modals/CriarRifa.vue'

const emitter = inject('emitter')
const storeRifas = useStoreRifas()

async function carregarRifas() {
  storeRifas.receberTodos()
}

onMounted(() => {
  carregarRifas()
  emitter.on('atualizarRifas', carregarRifas)
})
</script>

<style>
#rifas {
  padding: 0 0 0 300px;
  animation: fadeIn 0.3s linear;
}
@media screen and (max-width: 1000px) {
  #rifas {
    padding: 60px 0 0 0;
  }
}
</style>
