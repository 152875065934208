import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idAdministrador = helperToken.idAdministrador()

export default {
  receberPorId() {
    const method = 'GET'
    const url = `perfil/${idAdministrador}`

    return api(method, url, null, token)
  },

  alterarDados(payload) {
    const method = 'PUT'
    const url = `perfil/${idAdministrador}`
    const dados = payload

    return api(method, url, dados, token)
  },

  alterarSenha(payload) {
    const method = 'PATCH'
    const url = `perfil/senha/alterar/${idAdministrador}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
