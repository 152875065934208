<template>
  <button class="salvarModal">
    <p>{{ nome }}</p>
  </button>
</template>

<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  nome: {
    type: String,
    required: true
  }
})

const { nome } = toRefs(props)
</script>

<style scoped>
button.salvarModal {
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  background-color: var(--cor-verde);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s;
}

button.salvarModal:hover {
  background-color: var(--cor-verde-escuro);
}

button.salvarModal p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco-fixo);
  text-align: center;
  width: 100%;
}
</style>
