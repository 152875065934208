import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiRifas from '../api/admin/api-rifas'

export const useStoreRifas = defineStore('storeRifas', {
  state: () => {
    return {
      rifas: null,
      rifa: null,
      filtroBusca: ''
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiRifas.receberTodos()
        this.rifas = resp
        return resp
      } catch (error) {
        this.rifas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorId(idRifa) {
      try {
        const resp = await apiRifas.receberPorId(idRifa)
        this.rifa = resp
        return true
      } catch (error) {
        this.rifa = null
        return false
      }
    },

    async adicionar(payload) {
      try {
        const resp = await apiRifas.adicionar(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(idRifa, payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiRifas.alterarDados(idRifa, payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idRifa) {
      try {
        const resp = await apiRifas.deletar(idRifa)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
