<template>
  <section class="topo">
    <div class="nome">
      <div class="icone">
        <Svgs nome="medalha" />
      </div>
      <p>Rifas</p>
    </div>
    <div class="perfil" @click="router.push('/perfil')">
      <div class="texto">
        <h3>{{ storePerfil.usuario?.nome }}</h3>
        <p>{{ storePerfil.usuario?.email }}</p>
      </div>
      <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStorePerfil } from '@stores'
import Svgs from '@svgs'

const router = useRouter()
const storePerfil = useStorePerfil()
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.nome {
  display: flex;
  align-items: center;
}

.nome .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--cor-escuro-2);
}

.nome .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.nome p {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
}

.perfil {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.perfil:hover {
  opacity: 0.6;
}

.perfil .foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--cor-escuro-3);
  background-size: cover;
  background-position: center;
}

.perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 10px 0 0;
}

.perfil .texto h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.perfil .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}
@media screen and (max-width: 1000px) {
  section.topo {
    padding: 20px;
  }

  .nome .icone {
    width: 30px;
    height: 30px;
  }

  .nome .icone svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-branco);
  }

  .nome p {
    font-size: var(--f2);
  }

  .perfil .foto {
    width: 40px;
    height: 40px;
  }
}
</style>
