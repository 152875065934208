<template>
  <Modal nome="alterarSenha">
    <label>
      Senha atual
      <span>*</span>
    </label>
    <input v-model="state.senhaAtual" type="password" spellCheck="false" autocomplete="current-password" placeholder="Senha#Atual" @keydown.enter="alterarSenha" />

    <label>
      Nova senha de acesso
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="cadeado" />
      <input :type="state.mostrarSenhaUm ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.senha" @keydown.enter="alterarSenha" />
      <button class="ocultar" @click="toggleMostrarSenhaUm">
        <Svgs :nome="state.mostrarSenhaUm ? 'oculto' : 'visivel'" />
      </button>
    </div>
    <label>
      Repita a nova senha
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="cadeado" />
      <input :type="state.mostrarSenhaDois ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.confirmarSenha" @keydown.enter="alterarSenha" />
      <button class="ocultar" @click="toggleMostrarSenhaDois">
        <Svgs :nome="state.mostrarSenhaDois ? 'oculto' : 'visivel'" />
      </button>
    </div>

    <SalvarModal nome="Salvar Nova Senha" @click="alterarSenha()" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const state = reactive({
  senhaAtual: '',
  senha: '',
  confirmarSenha: '',
  mostrarSenhaUm: false,
  mostrarSenhaDois: false
})

const toggleMostrarSenhaUm = () => {
  state.mostrarSenhaUm = !state.mostrarSenhaUm
}

const toggleMostrarSenhaDois = () => {
  state.mostrarSenhaDois = !state.mostrarSenhaDois
}

async function alterarSenha() {
  if (!validarDados()) return

  const payload = {
    senhaAtual: state.senhaAtual,
    senhaNova: state.confirmarSenha
  }

  const alteradoOk = await storePerfil.alterarSenha(payload)
  if (alteradoOk !== true) return

  emitter.emit('atualizarPerfil')
  storeModal.fecharModal('alterarSenha')
}

function validarDados() {
  let mensagem

  if (state.senha === '' || state.senhaAtual === '' || state.confirmarSenha === '') mensagem = 'Preencha todos os campos'
  else if (state.senha !== state.confirmarSenha) mensagem = 'Senhas não conferem'
  else if (state.senha.length < 6 || state.confirmarSenha.length < 6) mensagem = 'Senha precisa ter 6 caracteres'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao criar conta', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.senhaAtual = ''
  state.senha = ''
  state.confirmarSenha = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(0deg, var(--degrade));
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.titulo .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .titulo .nome h3 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
  }
}
</style>
