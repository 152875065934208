<template>
  <nav>
    <div class="logo">
      <img @click="router.push('/painel')" src="https://arquivos.oficinadasrifas.com/imagens/logo-v1.svg" />
    </div>
    <div class="opcoes" :class="{ mostrar: state.mostrarMenu }">
      <h4>Essa é a área administrativa privada da Oficina das Rifas</h4>
      <button @click="router.push('/painel')" :class="{ ativo: rotaAtiva('/painel') }">
        <Svgs nome="casa" />
        Painel
      </button>
      <button @click="router.push('/rifas')" :class="{ ativo: rotaAtiva('/rifas') }">
        <Svgs nome="cupom" />
        Rifas
      </button>
      <button @click="router.push('/compradores')" :class="{ ativo: rotaAtiva('/compradores') }">
        <Svgs nome="usuarios" />
        Compradores
      </button>
      <button @click="router.push('/ganhadores')" :class="{ ativo: rotaAtiva('/ganhadores') }">
        <Svgs nome="medalha" />
        Ganhadores
      </button>
      <div class="perfil" @click="router.push('/perfil')">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <div class="texto">
          <h3>{{ storePerfil.usuario?.nome }}</h3>
          <p>{{ storePerfil.usuario?.email }}</p>
        </div>
      </div>
    </div>
    <button class="menu" @click="state.mostrarMenu = !state.mostrarMenu" :class="{ ativo: state.mostrarMenu }">
      <span class="um"></span>
      <span class="dois"></span>
    </button>
  </nav>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStorePerfil } from '@stores'
import Svgs from '@svgs'

const route = useRoute()
const router = useRouter()
const storePerfil = useStorePerfil()

const rotaAtiva = (rota) => route.path === rota

const state = reactive({
  mostrarMenu: false
})
</script>

<style scoped>
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  height: 100dvh;
  padding: 30px;
  background-color: var(--cor-escuro-2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.logo img {
  width: 100%;
  max-width: 160px;
}

.opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.opcoes button {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: transparent;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.opcoes button:hover {
  opacity: 0.7;
}

.opcoes button.ativo svg {
  fill: var(--cor-azul);
}

.opcoes button.ativo {
  color: var(--cor-azul);
  pointer-events: none;
}

.opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.opcoes button.destaque {
  font-family: var(--bold);
  background-color: var(--cor-azul);
  padding: 10px 16px;
  border-radius: 5px;
  transition: all 0.3s;
}

.opcoes button.destaque:hover {
  opacity: 1;
  background-color: var(--cor-azul-escuro);
}

.opcoes h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.opcoes .perfil {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  cursor: pointer;
  transition: all 0.3s;
}

.opcoes .perfil:hover {
  opacity: 0.7;
}

.opcoes .perfil .foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--cor-escuro-3);
  background-size: cover;
  background-position: center;
  margin: 0 10px 0 0;
}

.opcoes .perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.opcoes .perfil .texto h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.opcoes .perfil .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

button.menu {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: relative;
}

button.menu span {
  background-color: var(--cor-branco);
  height: 3px;
  position: absolute;
  transition: all 0.3s;
}

button.menu span.um {
  width: 40px;
  top: 10px;
  right: 0;
}

button.menu span.dois {
  width: 20px;
  bottom: 10px;
  right: 0;
}

button.menu.ativo span {
  background-color: var(--cor-vermelho);
}

button.menu.ativo span.um {
  width: 20px;
  transform: rotate(45deg);
  top: 18px;
}

button.menu.ativo span.dois {
  transform: rotate(-45deg);
  bottom: 18px;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  nav {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    min-height: 60px;
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    border-radius: none;
  }

  .logo img {
    width: 100%;
    max-width: 160px;
  }

  button.menu {
    display: flex;
  }

  .opcoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    position: fixed;
    top: 65px;
    right: 20px;
    height: auto;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    border-radius: 5px;
    background-color: var(--cor-escuro-2);
    border: 1px solid var(--cor-escuro-3);
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
    pointer-events: none;
    transition: all 0.3s;
  }

  .opcoes.mostrar {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    pointer-events: all;
  }

  .opcoes button {
    margin: 10px 0;
  }

  .opcoes h4 {
    margin: 0 0 10px 0;
  }

  .opcoes .perfil {
    margin: 10px 0 0 0;
  }

  .opcoes button svg {
    width: 15px;
    min-width: 15px;
    max-height: 15px;
    fill: var(--cor-branco);
    margin: 0 5px 0 0;
  }

  .opcoes button.destaque {
    font-family: var(--bold);
    background-color: var(--cor-azul);
    padding: 10px 16px;
    border-radius: 5px;
    transition: all 0.3s;
  }

  .opcoes button.destaque:hover {
    opacity: 1;
    background-color: var(--cor-azul-escuro);
  }
}
</style>
