<template>
  <section class="filtros">
    <div class="quadro">
      <div class="busca">
        <Svgs nome="lupa" />
        <input type="text" placeholder="Buscar por rifa..." spellcheck="false" />
      </div>
      <button class="filtros">
        <Svgs nome="filtro" />
        <p>Filtros</p>
      </button>
      <button
        class="criar"
        @click="
          abrirModal(
            'criarRifa', // nome do modal
            'mais', // nome do icone
            'Criar nova rifa' // titulo
          )
        ">
        <Svgs nome="mais-circulo" />
        <p>Criar nova rifa</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}
</script>

<style scoped>
section.filtros {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
}

.quadro {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--cor-escuro-2);
  border-radius: 10px;
  padding: 0 20px 0 0;
}

.quadro .busca {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
}

.quadro .busca svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  position: absolute;
  left: 30px;
  pointer-events: none;
}

.quadro .busca input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  background-color: var(--cor-escuro-2);
  border: none;
  padding: 35px 20px 35px 60px;
  border-radius: 10px;
  width: 100%;
}

.quadro button.filtros {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: var(--cor-escuro-3);
  transition: all 0.3s;
}

.quadro button.filtros:hover {
  background-color: var(--cor-escuro-4);
}

.quadro button.filtros svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.quadro button.filtros p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.quadro button.criar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 30px;
  border-radius: 10px;
  background: linear-gradient(50deg, #ffea00, #ff8800);
  background: -webkit-linear-gradient(50deg, #ffea00, #ff8800);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.quadro button.criar:hover {
  filter: brightness(110%);
}

.quadro button.criar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.quadro button.criar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

@media screen and (max-width: 1000px) {
  section.filtros {
    padding: 0 20px;
  }

  .quadro {
    padding: 0 10px 0 0;
  }

  .quadro .busca svg {
    left: 20px;
  }

  .quadro .busca input {
    padding: 25px 20px 25px 50px;
  }

  .quadro button.filtros {
    width: 45px;
    height: 45px;
    min-width: 45px;
    padding: 0;
  }

  .quadro button.filtros svg {
    width: 15px;
    min-width: 15px;
    margin: 0;
  }

  .quadro button.filtros p {
    display: none;
  }

  .quadro button.criar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  .quadro button.criar svg {
    width: 20px;
    min-width: 20px;
    margin: 0;
  }

  .quadro button.criar p {
    display: none;
  }
}
</style>
