<template>
  <div id="alerta" :class="{ [classe]: true, mostrar: visivel }" @click="storeAlerta.fecharAlerta()">
    <Svgs class="um" nome="check" />
    <Svgs class="dois" nome="atencao" />
    <p>{{ mensagem }}</p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useStoreAlerta } from '@stores'
import Svgs from '@svgs'

const storeAlerta = useStoreAlerta()
const { classe, visivel, mensagem } = storeToRefs(storeAlerta)
</script>

<style scoped>
div#alerta {
  display: none;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--cor-branco);
  transform: scale(1);
  padding: 0 20px;
  height: 50px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  animation: animacao 0.5s ease-in-out;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10000;
}

@keyframes animacao {
  0% {
    transform: scale(0.9);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    visibility: visible;
    pointer-events: all;
    transform: scale(1);
  }
}

div#alerta.mostrar {
  display: flex;
}

svg {
  width: 15px;
  border-radius: 2px;
  margin: 2px 10px 0 0;
  fill: var(--cor-branco-fixo);
}

p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  line-height: 1.5;
  text-align: left;
  width: 100%;
}

div#alerta.positivo {
  background-color: var(--cor-verde);
  box-shadow: 10px 20px 30px 0 #118e6839;
}

div#alerta.positivo svg.um {
  display: flex;
}

div#alerta.positivo svg.dois {
  display: none;
}

div#alerta.negativo {
  background-color: var(--cor-vermelho);
  box-shadow: 10px 20px 30px 0 #e6232639;
}

div#alerta.negativo svg.um {
  display: none;
}

div#alerta.negativo svg.dois {
  display: flex;
}

div#alerta {
  box-shadow: 10px 20px 30px 0 #3636361f;
}

div#alerta svg {
  fill: var(--cor-branco-fixo);
}

div#alerta svg.um {
  display: none;
}

div#alerta svg.dois {
  display: flex;
}
@media screen and (max-width: 1000px) {
  div#alerta {
    width: calc(100% - 40px);
  }
}
</style>
