<template>
  <section class="modal" :class="{ mostrar: modalAberto }" ref="scrollModal">
    <div class="background"></div>
    <div class="conteudo">
      <div class="titulo">
        <div class="icone">
          <Svgs :nome="dadosModal.icone" />
        </div>
        <div class="nome">
          <h3>{{ dadosModal.titulo }}</h3>
        </div>
        <button class="fechar" @click="fecharModal(nome)">
          <Svgs nome="x" />
        </button>
      </div>
      <div class="dados">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { computed, toRefs, ref, watch, onMounted, onUnmounted, inject } from 'vue'
import Svgs from '@svgs'

const props = defineProps({
  nome: {
    type: String,
    required: true
  }
})

const { nome } = toRefs(props)
const emitter = inject('emitter')
const storeModal = useStoreModal()
const modalAberto = computed(() => storeModal.modalAberto(nome.value))
const dadosModal = computed(() => storeModal.dadosDoModal(nome.value))

const scrollModal = ref(null)

const resetarScroll = () => {
  if (scrollModal.value) {
    scrollModal.value.scrollTop = 0
  }
}

function fecharModal() {
  storeModal.fecharModal(nome.value)
  emitter.emit('limparCampos')
}

function abrirModal() {
  emitter.emit('carregarCampos')
}

const handleEscape = (event) => {
  if (event.key === 'Escape' && modalAberto.value) {
    fecharModal()
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscape)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleEscape)
})

watch(modalAberto, (novoValor) => {
  if (novoValor) {
    resetarScroll()
    abrirModal()
  }
})
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.mostrar .background {
  opacity: 1;
  visibility: visible;
}

section.modal.mostrar button.fechar {
  top: 40px;
  transform: rotate(0deg);
}

section.modal.mostrar .conteudo {
  transform: translate(0px, 0px);
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000088;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
}

button.fechar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  transform: rotate(15deg);
  margin-left: auto;
  z-index: 13;
  transition: all 0.6s;
}

button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

button.fechar svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--cor-escuro-1);
  transform: translate(600px, 0px);
  padding: 20px 30px 40px 30px;
  width: 100%;
  max-width: 550px;
  min-height: 100dvh;
  z-index: 12;
  transition: all 0.6s;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: var(--cor-escuro-2);
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.titulo .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

section.modal .dados {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

section.modal.mostrar .dados {
  opacity: 1;
  visibility: visible;
  animation: aparecendo 0.6s linear;
}

@keyframes aparecendo {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1000px) {
  section.modal.mostrar button.fechar {
    top: 20px;
  }

  button.fechar svg {
    width: 25px;
    min-width: 25px;
  }

  .conteudo {
    border-radius: 0;
    padding: 20px 20px 40px 20px;
  }

  .titulo .nome h3 {
    font-size: var(--f2);
  }
}
</style>
