<template>
  <section class="topo">
    <!-- Quadro -->
    <div class="quadro">
      <img src="https://arquivos.oficinadasrifas.com/imagens/logo-v1.svg" class="logo" />
      <p>Entre em sua conta para administrar os sorteios e rifas de forma completa</p>
      <div class="input">
        <label>Seu e-mail</label>
        <input type="email" placeholder="email@email.com" spellcheck="false" autocomplete="email" v-model="state.email" @keydown.enter="entrar" />
      </div>
      <div class="input">
        <label>Sua senha</label>
        <input :type="state.mostrarSenha ? 'text' : 'password'" placeholder="senha#123" spellcheck="false" autoComplete="current-password" v-model="state.senha" @keydown.enter="entrar" />
        <button @click="toggleMostrarSenha">
          <Svgs nome="visualizar" />
        </button>
      </div>
      <button class="entrar" @click="entrar()" :disabled="state.carregando">
        Acessar área adminstrativa
        <span></span>
      </button>
      <a target="_blank" href="https://app.oficinadasrifas.com">Ir para plataforma dos usuários</a>
    </div>
    <!-- Copy -->
    <div class="copy">
      <p>Tecnologia por</p>
      <a target="_blank" href="https://oficinadasrifas.com"><img src="https://arquivos.oficinadasrifas.com/imagens/logo-v1.svg" /></a>
    </div>
  </section>
</template>

<script setup>
import { reactive, inject } from 'vue'
import apiAutenticacao from '@/api/admin/api-autenticacao'
import Svgs from '@svgs'

const emitter = inject('emitter')

const state = reactive({
  email: '',
  senha: '',
  carregando: false,
  mostrarSenha: false
})

const toggleMostrarSenha = () => {
  state.mostrarSenha = !state.mostrarSenha
}

function validarDados() {
  let mensagemDeAviso

  if (!state.email || !state.senha) mensagemDeAviso = 'Email e senha obrigatórios'
  else if (state.senha.length < 6) mensagemDeAviso = 'Senha deve conter no mínimo 6 caracteres'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao fazer login', mensagem: mensagemDeAviso })
  return false
}

async function entrar() {
  if (!validarDados()) return

  const payload = {
    email: state.email,
    senha: state.senha
  }

  state.carregando = true

  try {
    const resp = await apiAutenticacao.entrar(payload)
    localStorage.setItem('oficina-das-rifas-admin-token', resp.token)
    setTimeout(() => window.location.assign('/painel'), 500)
  } catch (error) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao fazer login', mensagem: error })
    state.carregando = false
    return
  }
}
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://arquivos.oficinadasrifas.com/imagens/background-login.png');
  background-size: cover;
  background-position: top center;
  min-height: 100dvh;
  padding: 20px 20px 100px 20px;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 450px;
  border: 1px solid var(--cor-escuro-4);
  background-color: #171718e4;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 10px;
  padding: 60px 40px;
}

.quadro img.logo {
  width: 100%;
  max-width: 200px;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  width: 100%;
  max-width: 300px;
  margin: 10px 0 0 0;
}

.quadro .input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 20px 0 0 0;
}

.quadro .input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-escuro-2);
  padding: 0 5px;
  position: absolute;
  top: -5px;
  left: 15px;
}

.quadro .input input {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-escuro-2);
  border: 1px solid var(--cor-escuro-4);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.quadro .input button {
  position: absolute;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.quadro .input button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
}

.quadro button.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  background-color: var(--cor-verde);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0 20px 0;
  transition: all 0.3s;
}

.quadro button.entrar:hover {
  background-color: var(--cor-verde-escuro);
}

.quadro button.entrar:disabled {
  color: var(--cor-verde-escuro);
  background-color: var(--cor-verde-escuro);
  pointer-events: none;
}

.quadro button.entrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.quadro button.entrar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--cor-branco);
  border-top: 3px solid transparent;
  position: absolute;
  animation: girando 1s linear infinite;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.quadro a {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  transition: all 0.3s;
}

.quadro a:hover {
  color: var(--cor-branco);
}

.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
}

.copy p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.copy img {
  width: 100%;
  max-width: 160px;
  margin: 0 0 0 10px;
}
@media screen and (max-width: 1000px) {
  .quadro {
    padding: 50px 30px;
  }
}
</style>
