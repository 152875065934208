<template>
  <section class="dados">
    <div class="quadro">
      <div class="icone">
        <Svgs nome="cupom" />
      </div>
      <div class="nome">
        <h3>51 rifas</h3>
        <p>Crie quantas rifas quiser na plataforma</p>
        <button @click="router.push('/rifas')">
          Ver rifas
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="dinheiro" />
      </div>
      <div class="nome">
        <h3>512.291</h3>
        <p>Total de bilhetes vendidos nas rifas</p>
        <button @click="router.push('/bilhetes')">
          Ver bilhetes
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="medalha" />
      </div>
      <div class="nome">
        <h3>41</h3>
        <p>Ganhadores nas rifas</p>
        <button @click="router.push('/ganhadores')">
          Ver ganhadores
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="usuarios" />
      </div>
      <div class="nome">
        <h3>3.713</h3>
        <p>Compradores no total</p>
        <button @click="router.push('/compradores')">
          Ver compradores
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const router = useRouter()
</script>

<style scoped>
section.dados {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 0 30px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 10px);
  background-color: var(--cor-escuro-2);
  border-radius: 10px;
  padding: 40px;
  margin: 0 0 20px 0;
  position: relative;
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, var(--degrade));
  border-radius: 20px;
}

.quadro .icone.diferente {
  background: linear-gradient(0deg, var(--degrade));
}
.quadro .icone svg {
  width: 45px;
  min-width: 45px;
  fill: var(--cor-branco-fixo);
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 30px;
}

.quadro .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  width: 100%;
  max-width: 250px;
}

.quadro button {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  padding: 10px 15px 10px 20px;
  margin: 10px 0 0 0;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-4);
  border-radius: 50px;
  transition: all 0.3s;
}

.quadro button:hover {
  border: 2px solid var(--cor-azul);
}

.quadro button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-escuro-4);
  margin: 0 0 0 10px;
}

@media screen and (max-width: 1000px) {
  section.dados {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .quadro {
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .quadro .icone {
    width: 60px;
    min-width: 60px;
    height: 85px;
  }

  .quadro .icone svg {
    width: 25px;
    min-width: 25px;
  }

  .quadro .nome {
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
    margin: 5px 0 5px 0;
  }

  .quadro .nome p {
    font-size: var(--f1);
    max-width: 300px;
  }

  .quadro button {
    padding: 10px 10px 10px 15px;
  }

  .quadro button svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-cinza-2);
    transition: all 0.3s;
  }
}
</style>
